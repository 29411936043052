import { IEnvironment, PROD_API, PROD_API2, SENTRY_DSN } from './environment.interface';

export const environment: IEnvironment = {
  name: 'production',
  production: true,
  api: PROD_API,
  api2: PROD_API2,
  chargifyKey: 'chjs_v5sp283cgszq5hfvhqsm6txw',
  chargifyHost: 'https://platform500-prod.chargify.com',
  sentryDsn: SENTRY_DSN,
};
